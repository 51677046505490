import * as React from "react";

import { IHomeProps } from "src/pages";

import Text from "../text";
import { ColumnWidthWrapper } from "../confines-wrappers";
import styled, { css } from "../../theme/styled";
import {
  fontSizeHelper,
  fontWeightHelper,
  colourHelper,
  spacingHelper
} from "../../theme/theme-helper";
import Link from "../link";
import { IconButton } from "../button";
import { CtaButton } from "../cta";
import Icon from "../icon/icon";

interface IProps {
  data: IHomeProps;
}

/**
 * WeDontBuildWebsites?
 */
const WeDontBuildWebsites: React.FC<IProps> = ({ data }) => (
  <Styled.Container>
    <ColumnWidthWrapper className="content-container">
      <div>
        <p>
          What we do:
        </p>

        <br />

        <Styled.Row>
          <ul>
            {/* DON'T WANNA DEAL WITH OUR GQL SO MANUALLY ADDING LINK */}

            {/* Using style.order to place them where I want */}

            <li
              key="content-creators"
              // Using order to place them where i want
              style={{ order: 2 }}
            >
              <Link
                href="/content-creators"
                color="brandColor"
                className="topic block"
              >
                Content Creators
              </Link>
            </li>

            {data.nodeHome.field_landing_page_links.map((link, i) => {
              return (
                <li
                  key={i}
                  style={{ order: i }}
                >
                  <Link
                    href={link.uri}
                    color="brandColor"
                    className="topic block"
                  >
                    {link.title}
                  </Link>
                </li>
              );
            })}
          </ul>

          <a
            href="/projects"
            className="cta block"
          >
            <span
              style={{
                display: "inline-block",
                width: "1.5em",
                height: "1.5em",
                marginInline: "-.5em .75em"
              }}
            >
              {svg}
            </span>

            <span>
              View our work
            </span>
          </a>
        </Styled.Row>

      </div>

      <div>
        <h4>
          Gravitywell is Bristol's Venture Studio
        </h4>

        <p className="description">
          We create digital products for startups and scaleups that people love.
        </p>
      </div>
    </ColumnWidthWrapper>
  </Styled.Container>
);

const Styled = {
  Container: styled.div`
    ${({ theme }) => css`
      --brand-color: ${colourHelper({
    theme,
    variant: "brandColor"
  })};
    `}
  
    background: black;
    color: white;

    .content-container > * {
      & + * {
        margin-top: min(20vh, 10rem);
      }
    }

    h4 {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "title"
  })};

@media screen and (min-width: 768px) {
  font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "display_1"
  })};
}
    }

    .description {
      color: #A09D9D;
      max-width: 75rem;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
      margin-bottom: min(10vmin, 250px);
    }
    
  `,
  Row: styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    
    ul {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      margin-bottom: min(10vmin, 250px);
      
      @media screen and (min-width: 768px) {
        flex: 1;
        margin: 0;
      }
      
      li {
        list-style: none;
        margin-right: 1em;
        margin-bottom: .75em;
        padding: 0;

        &:not(:hover) {
          --highlight: green !important;
        }
      }
    }

    .block {
      --highlight: #808080;
      
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0 2em;
      vertical-align: middle;
      background: #2C2B31;
      border-radius: 2px;
      border: 0 !important;
      box-shadow: inset 0 0 0 1px var(--highlight);

      &.topic {
        --highlight: transparent;
        @media screen and (max-width: 768px) {
          height: 32px;
          padding: 0 1em;
        }
      }

      &.cta {
        background: transparent;
        color: var(--highlight);
        font-weight: 300;
        @media screen and (max-width: 768px) {
          margin: 0 auto;
        }
      }
      
      &:hover {
        --highlight: var(--brand-color);
      }
    }
    
  `
};

const svg = (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    style={{ display: "block" }}
  >
    <g fill="currentColor">
      <path d="m20.71 19.29-3.4-3.39a7.92 7.92 0 0 0 1.69-4.9 8 8 0 1 0 -8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 1 0 1.42-1.42zm-15.71-8.29a6 6 0 1 1 6 6 6 6 0 0 1 -6-6z" />
    </g>
  </svg>
);

export default WeDontBuildWebsites;
